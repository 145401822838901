/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:19:15 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-01 18:26:17
 */
<template>
  <div class="course_details">
    <Banner></Banner>
    <div class="cont">
      <div class="cont_top">
        <div class="width_1200">
          <span>资料列表</span>
        </div>
      </div>
      <div class="width_1200">
        <!-- 课程资料 -->
        <courseData
          v-if="coursePackage.length > 0"
          :coursePackage="coursePackage"
          :course="course"
        ></courseData>
      </div>
    </div>
  </div>
</template>

<script>
import courseData from "../course/components/courseData";
import Banner from "./components/banner";
import { courseQueryPackageDetail } from "@/api/course";
export default {
  data () {
    return {
      course: {},
      coursePackage: [],
    };
  },
  components: {
    courseData, Banner
  },
  created () {
    this.init();
  },
  mounted () {

  },
  methods: {
    init () {
      this.course = this.$route.query;
      // console.log(this.course, '')
      // coursePackage 课程包（0不是1是）
      if (this.course.CoursePackage == 0) {
        this.coursePackage = [this.course];
      } else {
        this.postCourseQueryPackageDetail(this.course.Id);
      }
      this.show = true;
    },
    //获取课程包详情
    async postCourseQueryPackageDetail (id) {
      const res = await courseQueryPackageDetail(id);
      if (res.success === true) {
        this.coursePackage = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.course_details {
  .cont {
    .cont_top {
      height: 60px;
      background: #ffffff;
      span {
        display: inline-block;
        margin-right: 30px;
        line-height: 60px;
        cursor: pointer;
      }
    }
  }
}
</style>